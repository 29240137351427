import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './frontend.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons'; // default icon

const EnquiryForm = ({ config, apiUrl }) => {
    const [formData, setFormData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const { fields, title, bgColor, width, alignment, message, showStickyButton, stickyButtonPosition, stickyButtonIcon, customCSS } = config;

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apiUrl, formData)
            .then(response => {
                setSubmitted(true);
                setTimeout(() => setSubmitted(false), 3000);
                setFormData({});
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            });
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    return (
        <div>
            {showStickyButton && (
                <button className={`sticky-button ${stickyButtonPosition}`} onClick={togglePopup}>
                    {stickyButtonIcon ? (
                        <img src={stickyButtonIcon} alt="Enquiry Form" />
                    ) : (
                        <FontAwesomeIcon icon={faInfo} />
                    )}
                </button>
            )}
            

            {!showStickyButton && (
                <div style={{ width: width, textAlign: alignment, position: 'relative', backgroundColor: bgColor }}>
                    <form onSubmit={handleSubmit} className="enquiry-form">
                        <h3>{title}</h3>
                        {fields.map((field, index) => (
                            <div key={index} className="form-group">
                                <label>{field.name}</label>
                                {field.type === 'textarea' ? (
                                    <textarea
                                        value={formData[field.name] || ''}
                                        onChange={(e) => handleChange(field.name, e.target.value)}
                                        required
                                    />
                                ) : (
                                    <input
                                        type={field.type}
                                        value={formData[field.name] || ''}
                                        onChange={(e) => handleChange(field.name, e.target.value)}
                                        required
                                    />
                                )}
                            </div>
                        ))}
                        <button type="submit">Submit</button>
                    </form>
                    {submitted && (
                        <div className="overlay">
                            <div className="submission-message-container">
                                <div className="tick-icon">&#10004;</div>
                                <div className="submission-message">{message}</div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {showPopup && (
                <div className="popup-overlay" onClick={togglePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-popup" onClick={togglePopup}>×</button>
                        <form onSubmit={handleSubmit} className="enquiry-form">
                            <h3>{title}</h3>
                            {fields.map((field, index) => (
                                <div key={index} className="form-group">
                                    <label>{field.name}</label>
                                    {field.type === 'textarea' ? (
                                        <textarea
                                            value={formData[field.name] || ''}
                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                            required
                                        />
                                    ) : (
                                        <input
                                            type={field.type}
                                            value={formData[field.name] || ''}
                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                            required
                                        />
                                    )}
                                </div>
                            ))}
                            <button type="submit">Submit</button>
                        </form>
                        {submitted && (
                        <div className="overlay">
                            <div className="submission-message-container">
                                <div className="tick-icon">&#10004;</div>
                                <div className="submission-message">{message}</div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            )}
            <style>{customCSS}</style>
        </div>
    );
};

function renderEnquiryComponents() {
    const blocks = document.querySelectorAll('section[id^="mm_enquiry_form_block_"]');
    blocks.forEach(block => {
        const config = block.getAttribute('data-config');
        if (config) {
            const parsedConfig = JSON.parse(config);
            const container = block.querySelector('.react-enquiry-form-block');
            if (container) {
                ReactDOM.render(<EnquiryForm
                    config={parsedConfig}
                    apiUrl={parsedConfig.PY_API_URL + 'enquiry'}
                />, container);
            } else {
                console.error('Container element not found');
            }
        } else {
            console.error('Configuration not found');
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    renderEnquiryComponents();
});
